.App {
  display:flex;
  flex-direction: column;
  align-items: center;

}

.CheckBoxes {
  display:flex;
  justify-content: center;
  width: 100%;
  flex: 1;
}
.Controls{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top:20px;
  margin-bottom: 30px;
}
.Buttons{
  justify-content: center;
  align-items: center;
  display:flex;
  width: 100%;
}
.ButtonItem{
  margin-right: 20px;
}
.App-chart{
  width: 1600px;
  height: 900px;
}

.ColorSelect{
  display: flex;
  align-items: center;
  justify-content:center;
  margin:auto;
}
.DataPointSelect{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  margin:auto;
}
.DataPointSection{
  margin-top: 20px;
}
.NewDataSetSection{
}